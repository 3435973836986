import * as React from "react";
import { List, Datagrid, Edit, DateTimeInput, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, FormInput, ImageInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import RichTextInput from 'ra-input-rich-text';



export const QuestionList = (props) => (
    <List {...props}>
        <Datagrid>

            <TextField source="question_id" />
            <TextField source="title" />





            <EditButton basePath="/questions" />
            <DeleteButton basePath="/questions" />
        </Datagrid>
    </List>
);

const QuestionTitle = ({ record }) => {
    return <span>Question {record ? `"${record.title}"` : ''}</span>;
};

export const QuestionEdit = (props) => {


    return (<Edit title={<QuestionTitle />} {...props}>
        <SimpleForm>

            <TextField disabled source="post_id" />

            <TextInput source="title" />

            <TextInput source="text" />


            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput source="replies">
                <SimpleFormIterator>
                    <TextInput disabled source="reply_id" />
                    <TextInput disabled source="author_id" />

                    <TextInput source="text" />

                </SimpleFormIterator>



            </ArrayInput>








        </SimpleForm>
    </Edit >)

}



export const QuestionCreate = (props) => {


    return (<Create title={<QuestionTitle />} {...props}>
        <SimpleForm>

            <TextField disabled source="post_id" />

            <TextInput source="title" />

            <TextInput source="text" />


            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>




        </SimpleForm>
    </Create >)

}
