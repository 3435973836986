import './App.css';

import * as React from "react";
import { render } from 'react-dom';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import restProvider from 'ra-data-simple-rest';
import UserIcon from '@material-ui/icons/Group';
import Money from "@material-ui/icons/Money"
import { UserEdit, UserList } from './components/Users/Users';

import { UserRestProvider } from "./utils/RestProvider"
import { ProductCreate, ProductEdit, ProductList } from './components/Product/products';
import authProvider from './utils/authProvider';
import Dashboard from './dashboard/dashboard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ReplyIcon from '@material-ui/icons/Reply';
import { PostCreate, PostEdit, PostsList } from './components/Library/posts';
import { QuestionCreate, QuestionEdit, QuestionList } from './components/Forum/question';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { InquiryEdit, InquirysList } from './components/Inquiry/inquiries';
import { NotifEdit, NotifList } from './components/notifications/notifs';
import { ShippingCostsEdit, ShippingCostsList } from './components/Shipping/shippingCost';
import { CouponCreate, CouponEdit, CouponList } from './components/Product/coupons';
import { OrderList, OrderShow } from './components/Product/orders';


const App = () => {

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={UserRestProvider}
      title="MPT-c"
      dashboard={Dashboard}


    >

      <Resource name="users" list={UserList} edit={UserEdit} icon={UserIcon} />
      <Resource name="posts" create={PostCreate} list={PostsList} show={ShowGuesser} edit={PostEdit} icon={LibraryBooksIcon} />
      <Resource name="questions" create={QuestionCreate} list={QuestionList} edit={QuestionEdit} icon={ReplyIcon} />
      <Resource name="inquiries" list={InquirysList} edit={InquiryEdit} icon={NotListedLocationIcon} />
      <Resource name="notifications" show={ShowGuesser} list={NotifList} edit={NotifEdit} icon={NotificationImportantIcon} />

      <Resource name="orders" list={OrderList} icon={AssignmentIcon} show={OrderShow} />
      <Resource name="products" show={ShowGuesser} list={ProductList} create={ProductCreate} icon={AddShoppingCartIcon} edit={ProductEdit} />
      <Resource name="shippingCost" list={ShippingCostsList} edit={ShippingCostsEdit} icon={LocalShippingIcon} />
      <Resource name="coupons" list={CouponList} edit={CouponEdit} create={CouponCreate} icon={MoneyOffIcon} />



    </Admin>
  )


}


export default App;
