import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { UserList } from '../../components/Users/Users'
import { apiUrl, CRUD_apiUrl } from '../../utils/RestProvider'
import CardUser from './CardUser'


const searchUser = (e, setResults, setLoading) => {

    e.preventDefault()
    console.log({ e })
    setLoading(true)


    const { username } = e.target.elements
    const searchTerm = username.value

    if (searchTerm.length == 0) return

    axios.get(CRUD_apiUrl + "/users")
        .then(res => {
            setLoading(false)
            console.log({ data: res.data })

            const results = res.data.filter(item => item.id.includes(searchTerm) || item.email.includes(searchTerm))

            setResults(results)

        })
        .catch(err => {
            setLoading(false)
            console.error({ err })
        })
}




const Main = () => {


    const [targetUser, setTargetUser] = useState("")

    const [actionResults, setActionResults] = useState("")

    const [refresh, setRefresh] = useState(1)

    const [loading, setLoading] = useState(false)
    const [actionId, setActionId] = useState(0)







    return (

        <div className="flex flex-col  w-full border rounded-md  shadow-sm bg-gray-50  p-2 md:px-4 ">

            <div className="flex  flex-wrap space-x-4 text-gray-500  text-lg font-medium ">
                Allocate free accounts

            </div>

            <div className="py-10 " >

                <form onSubmit={(e) => searchUser(e, setActionResults, setLoading)} className="flex flex-wrap space-x-6">
                    <h3 className="text-gray-500 py-2">Enter valid user email/username </h3>


                    <input
                        min="4"
                        name="username"
                        placeholder="hamid@mail.com"
                        className="p-2 border rounded-lg"
                        type="text" onChange={(e) => setTargetUser(e.target.value)} />

                    <button type="submit" className=" font-medium  mx-5 text-blue-600 borde-b   border-blue-600 hover:opacity-50">
                        search

                    </button>

                </form>

                {actionResults.length > 0 &&

                    <div className="space-y-2  my-3">
                        <p className="text-gray-500 py-1">{actionResults.length} user(s) found:</p>

                        {

                            actionResults.map(item => (
                                <CardUser setRefresh={setRefresh} key={item.id} item={item} />
                            ))
                        }

                    </div>
                }{
                    !loading && actionResults.length == 0 &&
                    <p className="py-2 text-xs text-gray-500 ">Nothing found!</p>}

                {loading &&
                    <p>Searching...</p>}


            </div>


        </div>
    )
}

export default Main
