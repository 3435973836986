import React from 'react'

const Card = ({ icon, title, data, isLoading }) => {
    return (
        <div className={`  flex flex-col  space-y-3   p-4 md:p-8 bg-white border  border-blue-300  rounded-xl shadow-xl m-3 md:w-60 w-full  `}>
            <div className="w-16 text-blue-500 flex  ">
                {icon}
            </div>

            <div className="flex items-center justify-between">
                <p className="text-gray-400  font-medium ">{title}</p>

                <p className="text-xl md:text-3xl  text-gray-700 font-extrabold ">

                    {isLoading ?
                        <svg xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 animate-spin text-gray-500 " viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                        </svg>
                        :
                        data
                    }
                </p>
            </div>
        </div>
    )
}

export default Card
