import * as React from "react";
import { List, Datagrid, Show, SimpleShowLayout, Edit, Create, SimpleForm, DateField, ShowButton, NumberField, ArrayField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, ArrayInput, SimpleFormIterator, BooleanInput, BooleanField, NumberInput, AutocompleteArrayInput, SingleFieldList, ChipField } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import { useRecordContext } from 'react-admin';



const MoneyField = ({ source }) => {
    const record = useRecordContext();
    return (<div className="flex flex-col py-2">

        <span className="text-sm text-gray-500">{source}</span>


        <span
        >
            ${record && record[source]}</span>  </div>);
};

export const PostIcon = BookIcon;


export const OrderList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="orderId" />
            <TextField source="amount" />
            <TextField source="customerId" />
            <TextField source="addressShipping.country" />

            <ArrayField source="productsQuantity">
                <Datagrid>
                    <TextField source="productId" />
                    <NumberField source="quantity" />

                </Datagrid>
            </ArrayField>


            <ShowButton basePath="/orders" />


            {/* <DeleteButton basePath="/orders" /> */}

        </Datagrid>
    </List>
);


export const OrderShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="orderId" />

            <MoneyField source="amount" />
            <MoneyField source="shippingFees" />
            <MoneyField source="tax" />
            <TextField source="customerId" />
            <TextField source="addressShipping.country" />
            <TextField source="addressShipping.state" />
            <TextField source="addressShipping.city" />
            <TextField source="addressShipping.addressText" />
            <TextField source="addressShipping.zip" />

            <ArrayField source="productsQuantity">
                <Datagrid>
                    <TextField source="productId" />
                    <NumberField source="quantity" />
                </Datagrid>
            </ArrayField>



            <DateField showTime source="createdAt" />
        </SimpleShowLayout>
    </Show>
);


