import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, FormInput, ImageInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import RichTextInput from 'ra-input-rich-text';



export const PostsList = (props) => (
    <List {...props}>
        <Datagrid>

            <TextField source="post_id" />
            <TextField source="title" />


            <EditButton basePath="/posts" />
            <DeleteButton basePath="/posts" />
        </Datagrid>
    </List>
);

const UserTitle = ({ record }) => {
    return <span>post {record ? `"${record.title}"` : ''}</span>;
};

export const PostEdit = (props) => {


    return (<Edit title={<UserTitle />} {...props}>
        <SimpleForm>

            <TextField disabled source="post_id" />

            <TextInput source="title" />

            <TextInput source="mainImage" />

            <RichTextInput source="text" />
            <ArrayInput source="downloadables">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>




        </SimpleForm>
    </Edit >)

}



export const PostCreate = (props) => {


    return (<Create title={<UserTitle />} {...props}>
        <SimpleForm>

            <TextField disabled source="post_id" />

            <TextInput source="title" />

            <TextInput source="mainImage" />

            <RichTextInput source="text" />
            <ArrayInput source="downloadables">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>




        </SimpleForm>
    </Create >)

}
