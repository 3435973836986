
/**
 * 
 * @param {String} resource  the resource name 
 * @param {*} json  json  response
 * @returns {Array}  the right array containing  id mapping ( converts from the native id naming to id)
 */


export const rightIdMapping = (resource, json) => {

    switch (resource) {
        case 'products':
            return json.map(item => { return { ...item, id: item.productId } });

        case 'posts':
            return json.map(item => { return { ...item, id: item.post_id } });

        case 'questions':
            return json.map(item => { return { ...item, id: item.question_id } });

        case 'coupons':
            return json.map(item => { return { ...item, id: item.couponId } });

        case 'orders':
            return json.map(item => { return { ...item, id: item.orderId } });





        default:
            return json.map(item => { return { ...item, id: item.id } });
    }
}






/**
 * 
 * @param {String} resource  the resource name 
 * @param {*} json  json  response
 * @returns {Array}  the right array containing  id mapping ( converts from the native id naming to id)
 */


export const rightIdMappingSingle = (resource, json) => {
    console.log({
        resource,
        json
    })

    switch (resource) {
        case 'products':
            return { ...json, id: json.productId }

        case 'posts':
            return { ...json, id: json.post_id };

        case 'questions':
            return { ...json, id: json.question_id };

        case 'coupons':
            return { ...json, id: json.couponId };

        case 'orders':
            return { ...json, id: json.orderId };






        default:
            return { ...json, id: json.id };
    }
}

