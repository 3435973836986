import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { List, Datagrid, Edit, DateTimeInput, ListController, DateField, Create, SimpleForm, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, FormInput, ImageInput, ArrayInput, SimpleFormIterator, BooleanField, RichTextField, BooleanInput, ShowButton } from 'react-admin';




export const NotifList = (props) => (
    <List {...props} >
        <Datagrid>
            <BooleanField source="isVisible" />
            <DateField source="runsFrom" />
            <DateField source="runsTo" />

            <RichTextField source="text" />

            <BooleanField source="isMaintenance" />
            <DateField source="maintenanceFrom" />
            <DateField source="maintenanceTo" />
            <ShowButton basePath="/notifications" />
            <EditButton basePath="/notifications" />


        </Datagrid>
    </List>
);







const NotifTitle = ({ record }) => {
    return <span>notification: {record ? `"${record.text}"` : ''}</span>;
};


export const NotifEdit = (props) => {

    return (<Edit title={<NotifTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <RichTextInput source="text" />
            <BooleanInput source="readMore" />
            <RichTextInput source="readMoreText" />
            <DateTimeInput source="runsFrom" />
            <DateTimeInput source="runsTo" />
            <BooleanInput source="isVisible" />
            <BooleanInput source="isMaintenance" />
            <DateTimeInput source="maintenanceFrom" />
            <DateTimeInput source="maintenanceTo" />
            <EditButton basePath="/notifications" />




        </SimpleForm>
    </Edit >)

}
