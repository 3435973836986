import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { nanoid } from 'nanoid'
import { rightIdMapping, rightIdMappingSingle } from './methods';


 export const apiUrl = 'https://mptc-backend-6w4ru.ondigitalocean.app'
 export const CRUD_apiUrl = 'https://mptc-backend-6w4ru.ondigitalocean.app/crud'

//export const apiUrl = 'http://localhost:5000';
//export const CRUD_apiUrl = 'http://localhost:5000/crud'

// to  dos for now ?
// => either working on the canadian projects or working on the mptc
// canadian : already delay , should have meeting while really can 
// mptc: best opportunity to finish product selling  (product listing) , balance byding + email 

// only research thing 



//export const apiUrl = 'https://mptc-fiverr.herokuapp.com';
// const httpClient = fetchUtils.fetchJson;


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }


    const token = localStorage.getItem('token');
    console.log({ token })
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

export const UserRestProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${CRUD_apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: rightIdMapping(resource, json),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${CRUD_apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: rightIdMappingSingle(resource, json),
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${CRUD_apiUrl}/${resource}/?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${CRUD_apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${CRUD_apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: rightIdMappingSingle(resource, json) })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${CRUD_apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${CRUD_apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${CRUD_apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${CRUD_apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
};