import decodeJwt from 'jwt-decode';


const url = {


   
    remote: "https://mptc-backend-6w4ru.ondigitalocean.app/admin/authenticate",
    local: "http://localhost:5000/admin/authenticate"

 //remote: "https://mptc-fiverr.herokuapp.com/admin/authenticate",
}


const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(url.remote, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log(data)
                const decodedToken = decodeJwt(data.token);
                localStorage.setItem('token', data.token);
                localStorage.setItem('permissions', decodedToken.permissions);
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    getIdentity: () => { /* ... */ },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider