import * as React from "react";
import { List, Datagrid, Edit, DateTimeInput, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, FormInput, ImageInput, ArrayInput, SimpleFormIterator, NumberField, NumberInput, BooleanField, BooleanInput, ArrayField } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import RichTextInput from 'ra-input-rich-text';



export const CouponList = (props) => (
    <List {...props}>
        <Datagrid>

            <TextField source="couponId" />

            <BooleanField souce="pourcentage" />




            <DateField source="validFrom" />
            <DateField source="validTo" />

            <BooleanField source="hasMinimum" />
            <NumberField source="minimumOrder" />




            <EditButton basePath="/coupons" />
            <DeleteButton basePath="/coupons" />
        </Datagrid>
    </List>
);

const CouponTitle = ({ record }) => {
    return <span>Coupon {record ? `"${record.couponId}"` : ''}</span>;
};

export const CouponEdit = (props) => {


    return (<Edit title={<CouponTitle />} {...props}>
        <SimpleForm>

            <TextInput disabled source="couponId" />

            <NumberInput source="pourcentage" />
            <DateTimeInput source="validFrom" />
            <DateTimeInput source="validTo" />

            <ArrayInput source="targetProductsId">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>



            <BooleanInput source="hasMinimum" />
            <NumberInput source="minimumOrder" />







        </SimpleForm>
    </Edit >)

}



export const CouponCreate = (props) => {


    return (<Create title={<CouponTitle />} {...props}>
        <SimpleForm>

            <TextInput source="couponId" />

            <NumberInput source="pourcentage" />
            <DateTimeInput source="validFrom" />
            <DateTimeInput source="validTo" />

            <ArrayInput source="targetProductsId">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>



            <BooleanInput source="hasMinimum" />
            <NumberInput source="minimumOrder" />

        </SimpleForm>
    </Create >)

}
