
import React, { useEffect, useState } from 'react'
import { IconNewuser } from '../icons/mainIcons'
import GlobalParameters from './ActionsBox/GlobalParameters'
import Main from './ActionsBox/main'
import Card from './Card'
import axios from "axios"


import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssignmentIcon from '@material-ui/icons/Assignment';

import UserIcon from '@material-ui/icons/Group';

import { apiUrl, CRUD_apiUrl } from '../utils/RestProvider'



const Dashboard = () => {

    const [allUser, setAllusers] = useState(0)
    const [allQst, setAllQsts] = useState(0)
    const [allOrders, setAllOrders] = useState(0)

    const [isLoadingUsers, setIsLoadingUsers] = useState(true)
    const [isLoadingQsts, setIsLoadingQsts] = useState(true)
    const [isLoadingOrders, setIsLoadingOrders] = useState(true)


    useEffect(() => {

        axios.get(CRUD_apiUrl + "/users")
            .then(res => { setAllusers(res.data.length); setIsLoadingUsers(false) })
            .catch(err => { console.error({ errorGettingUser: err }); setIsLoadingUsers(false) })

        axios.get(CRUD_apiUrl + "/questions")
            .then(res => { setAllQsts(res.data.length); setIsLoadingQsts(false) })
            .catch(err => { console.error({ errorGettingUser: err }); setIsLoadingQsts(false) })

        axios.get(CRUD_apiUrl + "/orders")
            .then(res => { setAllOrders(res.data.length); setIsLoadingOrders(false) })
            .catch(err => { console.error({ errorGettingUser: err }); setIsLoadingOrders(false) })

    }, [])

    return (
        <div className="flex flex-col p-3 md:p-6 bg-gray-100 h-full w-full">

            <h1 className="text-2xl font-medium py-3 pt-6">Overview</h1>

            <div className="statistics flex flex-wrap flex-auto  justify-evenly pb-8">

                <Card icon={<UserIcon />} isLoading={isLoadingUsers} data={allUser} title="All users" />
                <Card icon={<IconNewuser />} isLoading={isLoadingQsts} data={allQst} title="All Questions" />
                <Card icon={<AssignmentIcon />} isLoading={isLoadingOrders} data={allOrders} title="All orders" />


            </div>


            <h1 className="text-2xl font-medium py-3">Global Parameters</h1>

            <GlobalParameters />


            <h1 className="text-2xl font-medium py-3 pt-6">Actions</h1>

            <Main />



        </div>
    )
}

export default Dashboard
