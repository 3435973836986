import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { UserList } from '../../components/Users/Users'
import { apiUrl, CRUD_apiUrl } from '../../utils/RestProvider'
import CardUser from './CardUser'

import toast, { Toaster } from "react-hot-toast"


const searchUser = (e, setResults, setLoading) => {

    e.preventDefault()
    console.log({ e })
    setLoading(true)


    const { username } = e.target.elements
    const searchTerm = username.value

    if (searchTerm.length == 0) return

    axios.get(CRUD_apiUrl + "/users")
        .then(res => {
            setLoading(false)
            console.log({ data: res.data })

            const results = res.data.filter(item => item.id.includes(searchTerm) || item.email.includes(searchTerm))

            setResults(results)

        })
        .catch(err => {
            setLoading(false)
            console.error({ err })
        })
}




const GlobalParameters = () => {


    const [targetUser, setTargetUser] = useState("")

    const [actionResults, setActionResults] = useState("")

    const [refresh, setRefresh] = useState(1)

    const [isChange, setIsChange] = useState(false)


    const [results, setResults] = useState({
        minEntriesPerResearchReport: null,
        ShippingFactor: null,
        IdUsersCanGenerateExcelReport: []

    })

    const [dataToSubmit, setDataToSubmit] = useState({
        minEntriesPerResearchReport: null,
        ShippingFactor: null,
        IdUsersCanGenerateExcelReport: []

    })

    const [loading, setLoading] = useState(false)
    const [actionId, setActionId] = useState(0)


    useEffect(() => {

        setLoading(true)


        axios.get(CRUD_apiUrl + "/gps")
            .then(res => {
                setLoading(false)
                console.log({ data: res.data })
                setResults(res.data[0])
                setDataToSubmit(res.data[0])

            })
            .catch(err => {
                setLoading(false)
                console.error({ err })
            })



    },

        [])







    return (

        <div className="flex flex-col  w-full border rounded-md  shadow-sm bg-gray-50  p-2 md:px-4 ">

            <Toaster />
            <div className="flex  flex-wrap space-x-4 text-gray-500  text-lg font-medium py-2 pb-8 ">
                Change Website parameters

            </div>




            <div className=" " >

                <div className="MInEnt flex flex-wrap items-center space-x-3 pb-3">



                    <h3 className="text-gray-500 py-2 w-80">Minimum Entries for  Research Report </h3>

                    <input

                        value={dataToSubmit?.minEntriesPerResearchReport}

                        className="p-2 border rounded-lg"
                        type="number"
                        disabled={!isChange}
                        onChange={(e) => setDataToSubmit({ ...dataToSubmit, minEntriesPerResearchReport: parseInt(e.target.value) })} />

                </div>

                <div className="Shipping flex flex-wrap items-center space-x-3  py-3">



                    <h3 className="text-gray-500 py-2 w-80">Shipping Factor </h3>

                    <input
                        value={dataToSubmit?.ShippingFactor}
                        className="p-2 border rounded-lg"
                        type="number"
                        disabled={!isChange}
                        onChange={(e) => setDataToSubmit({ ...dataToSubmit, ShippingFactor: parseInt(e.target.value) })} />


                </div>



                <div className="ExcelUser flex flex-wrap items-center space-x-3  py-3">



                    <h3 className="text-gray-500 py-2 w-80">Users who can generate Excel Reports </h3>


                    <select className="w-44 border rounded-lg p-1">
                        {
                            results.IdUsersCanGenerateExcelReport.map(item => (<option>
                                {item}
                            </option>))
                        }
                    </select>

                    {
                        isChange &&

                        <input

                            placeholder="user's username"

                            className="p-2 border rounded-lg"
                            type="text"
                            disabled={!isChange}
                            onChange={(e) => setDataToSubmit({ ...dataToSubmit, IdUsersCanGenerateExcelReport: [...dataToSubmit.IdUsersCanGenerateExcelReport, e.target.value] })}

                        />
                    }


                </div>



                <div className="flex items-center space-x-4  justify-end p-4">

                    <button
                        onClick={() => setIsChange(!isChange)}

                        type="submit" className=" font-medium  mx-5 text-blue-600  hover:bg-blue-500 hover:text-gray-100 rounded-lg p-1 w-20">
                        {isChange ? 'Cancel' : 'Change'}

                    </button>

                    <button
                        disabled={!isChange}

                        onClick={() => {


                            const UpdatePromise = axios.put(CRUD_apiUrl + "/gps/1", dataToSubmit)



                            toast.promise(UpdatePromise, {
                                loading: 'Updating ...',
                                success: (res) => {
                                    if (res.data.status)
                                        return "Success!"
                                    return "Error!"
                                },
                                error: (err) => `Error! Try again`,
                            });




                        }}



                        type="submit" className={` font-medium  mx-5  ${isChange ? 'bg-blue-500 text-gray-100 hover:bg-gray-100 hover:text-blue-600 border border-gray-100 hover:border-blue-500' : 'cursor-not-allowed bg-gray-500 text-white'}  rounded-lg p-1 w-20`}>
                        Save

                    </button>
                </div>





            </div>


        </div>
    )
}

export default GlobalParameters
