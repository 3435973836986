import * as React from "react";
import { List, Datagrid, Edit, DateTimeInput, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, FormInput, ImageInput, ArrayInput, SimpleFormIterator } from 'react-admin';



export const InquirysList = (props) => (
    <List {...props}>
        <Datagrid>

            <TextField source="id" />

            <TextField className="text-blue-600 text-lg" source="inquiryStatus" />

            <TextField source="subject" />
            <TextField source="senderEmail" />
            <TextField source="text" />

            <DateField source="createdAt" />





            <EditButton basePath="/inquiries" />
            <DeleteButton basePath="/inquiries" />
        </Datagrid>
    </List>
);

const InquiryTitle = ({ record }) => {
    return <span>Inquiry {record ? `"${record.title}"` : ''}</span>;
};

export const InquiryEdit = (props) => {


    return (<Edit title={<InquiryTitle />} {...props}>
        <SimpleForm>

            <TextField disabled source="id" />

            <TextInput source="subject" />

            <TextInput source="senderEmail" />

            <TextInput source="text" />
            <TextInput source="inquiryStatus" />




        </SimpleForm>
    </Edit >)

}



