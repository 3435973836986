import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, DateField, NumberField, ArrayField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, ArrayInput, SimpleFormIterator, BooleanInput, BooleanField, NumberInput, AutocompleteArrayInput, SingleFieldList, ChipField } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
export const PostIcon = BookIcon;


export const ProductList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="productId" />
            <TextField source="name" />
            <TextField source="description" />
            <BooleanField source="isAvailable" />

            <BooleanField source="hasTargetAccounts" />
            <ArrayField source="targetAccounts">
                <TextField source="targetAccounts" />

            </ArrayField>


            <EditButton basePath="/products" />
            <DeleteButton basePath="/products" />

        </Datagrid>
    </List>
);


export const ProductShow = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="productId" />
            <TextField source="name" />
            <ArrayField source="prices">
                <Datagrid>
                    <TextField source="price" />
                    <BooleanField source="hasMinimum" />
                    <NumberField source="minimumItems" />
                    <BooleanField source="hasMaximum" />
                    <NumberField source="maximumItems" />

                </Datagrid>
            </ArrayField>
            <TextField source="description" />

            <BooleanField source="isAvailable" />
            <TextField source="textAboutAvailability" />


            <BooleanField source="hasTargetAccounts" />
            <ArrayField source="targetAccounts">
                <TextField source="targetAccounts" />
            </ArrayField>


            <EditButton basePath="/products" />
            <DeleteButton basePath="/products" />




        </Datagrid>
    </List>
);



const ProductTitle = ({ record }) => {
    return <span>Product {record ? `"${record.productId}"` : ''}</span>;
};

export const ProductEdit = (props) => (
    <Edit title={<ProductTitle />} {...props}>
        <SimpleForm>
            <TextField source="productId" />
            <TextInput source="name" />
            <TextInput source="description" />


            <BooleanInput source="hasTargetAccounts" />


            <AutocompleteArrayInput source="targetAccounts" choices={[
                { id: 'standard', name: 'standard' },
                { id: 'research', name: 'research' },
                { id: 'institutionel', name: 'institutionel' },
            ]} />


            <ArrayInput source="prices">
                <SimpleFormIterator>
                    <NumberInput source="price" />
                    <BooleanInput source="hasMinimum" />
                    <NumberInput source="minimumItems" />
                    <BooleanInput source="hasMaximum" />
                    <NumberInput source="maximumItems" />
                </SimpleFormIterator>
            </ArrayInput>






            <BooleanInput source="isDigital" />
            <BooleanInput source="isReport" />
            <TextInput source="linkToDigitalProduct" />




            <TextInput source="mainImage" />

            <ArrayInput source="images">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>


            <BooleanInput source="isAvailable" />
            <TextInput source="textAboutAvailability" />



        </SimpleForm>
    </Edit>
);




export const ProductCreate = (props) => {


    return (<Create title={<ProductTitle />} {...props}>
        <SimpleForm>

            <TextInput source="productId" />
            <TextInput source="name" />

            <TextInput source="description" />


            <BooleanInput source="hasTargetAccounts" />
            <AutocompleteArrayInput source="targetAccounts" choices={[
                { id: 'standard', name: 'standard' },
                { id: 'research', name: 'research' },
                { id: 'institutional', name: 'institutional' },
            ]} />



            <ArrayInput source="prices">
                <SimpleFormIterator>
                    <NumberInput source="price" />
                    <BooleanInput source="hasMinimum" />
                    <NumberInput source="minimumItems" />
                    <BooleanInput source="hasMaximum" />
                    <NumberInput source="maximumItems" />
                </SimpleFormIterator>
            </ArrayInput>



            <BooleanInput source="isDigital" />
            <BooleanInput source="isReport" />
            <TextInput source="linkToDigitalProduct" />

            <BooleanInput source="isAvailable" />
            <TextInput source="textAboutAvailability" />

            <TextInput source="mainImage" />

            <ArrayInput source="images">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>


        </SimpleForm>
    </Create >)

}
