
import React, { useState, useEffect } from 'react'
import AccountCircle from "@material-ui/icons/AccountCircle"



import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { apiUrl } from '../../utils/RestProvider';



const CardUser = ({ item, setRefresh }) => {

    const [msg, setMsg] = useState({
        data: "",

    })

    const [open, setOpen] = useState(false)

    const [choice, setChoice] = useState(1)
    const [balance, setBalance] = useState(item.balance)

    const [periodStart, setPeriodStart] = useState(new Date())


    const [periodEnd, setPeriodEnd] = useState(null)

    const [isFreePeriod, setIsFreePeriod] = useState(false)




    useEffect(() => {

        setPeriodEnd(null)

    }, [periodStart])



    return (
        <div
            key={item.id}
            className="border-2  flex flex-col items-stretch   shadow-sm bg-white   w-80 p-3 md:p-6 rounded-md"
        >

            <div className="flex items-center flex-col p-2">
                <AccountCircle />
            </div>

            <div className=" flex items-center   space-x-5">
                <label className="text-gray-400 text-sm">
                    username
                </label>
                <p className="flex space-x-2 text-lg font-medium  text-gray-800">

                    {item.id}
                </p>

            </div>

            <div className=" flex items-center   space-x-5 pb-3">
                <label className="text-gray-400 text-sm">
                    Email
                </label>
                <p className="flex space-x-2 text-lg py-1  font-medium text-gray-800 ">

                    {item.email}
                </p>

            </div>

            <div className="Choices  py-4  space-y-2 border-t">


                <div className="text-sm font-medium text-center text-gray-500">
                    <h4 className="underline pb-4">  allocation</h4>
                </div>

                <div className="BalanceBased flex  items-center space-x-5  ">

                    <label className="text-gray-600 w-52">Balance-based allocation</label>
                    <input type="radio" checked={choice == 1} onChange={() => setChoice(1)} />


                </div>


                <div className="BalanceBased items-center flex space-x-5 ">

                    <label className="text-gray-600 w-52">Period-based allocation</label>
                    <input type="radio" checked={choice == 0} onChange={() => setChoice(0)} />

                </div>

                {
                    choice == 1 ?

                        <form
                            className=" bg-gray-50 p-2 rounded-md  py-3">
                            <label className="text-gray-500">Enter  the New Balance  </label>
                            <input
                                min="0"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                                type="number"
                                name="balance"
                                className="p-2 border rounded-lg"
                            />

                            <div className=" flex items-center   space-x-5">
                                <label className="text-gray-500  ">
                                    current user's balance
                                </label>
                                <p className="flex space-x-2 text-lg font-medium  text-blue-500">

                                    {item.balance}
                                </p>

                            </div>

                        </form>



                        :
                        <div>
                            <form
                                className=" flex flex-col space-y-2 bg-gray-50 p-2 md:p-3 rounded-md  py-3 ">

                                <h5 className="text-sm text-gray-400   text-center"> current Stat</h5>

                                <div className=" flex items-center   space-x-5">
                                    <label className="text-gray-500  ">
                                        Account has free period
                                    </label>
                                    <p className="flex space-x-2 text-lg font-medium  text-blue-500">

                                        {item.freePeriod ? "True" : "false"}
                                    </p>



                                </div>

                                {

                                    item.freePeriod &&
                                    <div >


                                        <div>
                                            from <strong>{new Date(item.freePeriodStart).toLocaleDateString()}</strong>
                                            to
                                            <strong>{new Date(item.freePeriodEnd).toLocaleDateString()}</strong>
                                        </div>



                                        <button
                                            onClick={() => {

                                                //   confirm("would you really stop the free period ?")

                                                if (window.confirm("sure"))
                                                    axios.put(apiUrl + "/user/update/balance/" + item.id, {
                                                        freePeriod: false
                                                    })
                                                        .then(res => {
                                                            if (res.data.status) alert("period of free usage has been stoped!")

                                                        })
                                                        .catch(e => console.log({ error_stopping_period: e }))

                                            }}


                                            className=" text-gray-500 underline  py-1 hover:text-blue-600 italic  ">
                                            stop free period

                                        </button>




                                    </div>



                                }

                                <h5 className="text-sm text-gray-400 pt-4 text-center"> new Free period</h5>

                                <div className="flex flex-wrap items-center space-x-2 ">
                                    <label className="w-10">from: </label>

                                    <DatePicker
                                        className="border p-2 rounded-md bg-gray-100"
                                        showTimeSelect
                                        showTimeInput
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}

                                        selected={periodStart}
                                        onChange={date => { setPeriodStart(date) }}

                                    />

                                </div>



                                <div className="flex flex-wrap items-center space-x-2 ">
                                    <label className="w-10">to: </label>

                                    <DatePicker
                                        className="border p-2 rounded-md bg-gray-100"
                                        showTimeSelect
                                        showTimeInput
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={periodStart}

                                        selected={periodEnd}
                                        onChange={date => { setPeriodEnd(date); setIsFreePeriod(true) }}

                                    />
                                </div>




                            </form>
                        </div>


                }


            </div>

            <button
                onClick={() => {


                    axios.put(apiUrl + "/user/update/balance/" + item.id, {
                        freePeriod: isFreePeriod,
                        balance: balance ? parseInt(balance) : parseInt(item.balance),

                        freePeriodStart: isFreePeriod ? periodStart : item.periodStart,
                        freePeriodEnd: isFreePeriod ? periodEnd : item.periodEnd



                    })
                        .then(res => {
                            if (res.data.status) alert("balance is updated! click search again")

                        })
                        .catch(e => console.log({ error_updating_period: e }))

                }}

                className="my-2  px-2 p-1 rounded-md text-sm border border-green-500 text-green-500 hover:opacity-50 ">
                Allocate
            </button>
        </div >
    )
}

export default CardUser
