import * as React from "react";
import { List, Datagrid, Edit, DateTimeInput, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, DeleteButton, EmailField, FormInput, ImageInput, ArrayInput, SimpleFormIterator, NumberField, BooleanField, NumberInput, BooleanInput, ArrayField } from 'react-admin';



export const ShippingCostsList = (props) => (
    <List {...props} perPage={5}>
        <Datagrid perpage   >

            <TextField source="id" />

            <TextField source="countryName" />

            <ArrayField source="costPerProduct">
                <Datagrid>
                    <TextField source="productId" />
                    <NumberField source="regularCost" />
                    <NumberField source="courierCost" />
                </Datagrid>
            </ArrayField>

            <BooleanField source="isShippingSupported" />

            <EditButton basePath="/shippingCost" />
        </Datagrid>
    </List>
);

const ShippingCostTitle = ({ record }) => {
    return <span>Inquiry {record ? `"${record.title}"` : ''}</span>;
};

export const ShippingCostsEdit = (props) => {


    return (<Edit title={<ShippingCostTitle />} {...props}>
        <SimpleForm>

            <TextField source="id" />

            <TextInput source="countryName" />

            <ArrayInput source="costPerProduct">
                <SimpleFormIterator>
                    <TextInput source="productId" />
                    <NumberInput source="regularCost" />
                    <NumberInput source="courierCost" />
                </SimpleFormIterator>
            </ArrayInput>




            <NumberInput source="vat" />
            <BooleanInput source="isShippingSupported" />


        </SimpleForm>
    </Edit >)

}



